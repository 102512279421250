export const environment = {
  production: false,
  stage: true,
  appVersion: '0.0.1',
  appDistVersion: '0.0.1',
  name: 'spotlite-stage',
  
  baseUrl: 'https://web-eagleeye-stage-be.azurewebsites.net',
  venueUrl: 'https://stage.eagleeyeapp.ai',
  publicUrl: 'https://stage.spotlite.app',
  proxyUrl: 'https://web-eagleeye-proxy-stage-be.azurewebsites.net',

  sentryName: 'spotlite-stage',
  sentryDsn: 'https://0a0be104ad5f7c893c9e9ba08cfb4eed@o4508129477394432.ingest.us.sentry.io/4508219336163328',
  sentryTracesSampleRate: 0.3,
  sentryReplaysSessionSampleRate: 0.1,
  sentryReplaysOnErrorSampleRate: 1.0,
  sentryProfilesSampleRate: 0.1,
  sentryDebug: false,
};